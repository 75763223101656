
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { apiFinanceShopReportDataLists } from '@/api/finance'
import ExportData from '@/components/export-data/index.vue'
import LsPagination from '@/components/ls-pagination.vue'
import {
    RequestPaging,
    timeFormat
} from '@/utils/util'
@Component({
    components: {
        LsPagination,
        ExportData
    }
})
export default class ShopLists extends Vue {
    @Prop({ default: '10vh' }) top!: string | number //弹窗的距离顶部位置
    @Prop({ default: '' }) ids!: string | number //门店ID

    /** S Data **/
    visible = false //是否
    form = {
        id: this.ids,
        time: 'month',
        year: '',
        month: ''
    }
    // 分页查询
    pager: RequestPaging = new RequestPaging()
    apiFinanceShopReportDataLists = apiFinanceShopReportDataLists // 传递给导出组件的api
    /** E Data **/

    /** S Method **/
    //获取用户列表数据
    getList() {
        this.pager.request({
            callback: apiFinanceShopReportDataLists,
            params: this.form
        })
    }

    changeDate() {
        if (this.form.time == 'month') {
            this.form.year = timeFormat(Number(new Date().getTime() + ''), 'yyyy')
        } else if (this.form.time == 'day') {
            this.form.year = timeFormat(Number(new Date().getTime() + ''), 'yyyy')
            this.form.month = timeFormat(Number(new Date().getTime() + ''), 'yyyy-mm')
        }
        this.getList()
    }

    getYearDate() {
        this.form.month = ''
        this.getList()
    }

    getMonthDate() {
        this.form.year = ''
        this.getList()
    }

    // 点击取消
    handleEvent(type: 'cancel' | 'confirm') {
        if (type === 'cancel') {
            this.close()
        }
        if (type === 'confirm') {
            this.close()
        }
    }

    // 打开弹窗
    onTrigger() {
        this.form.id = this.ids
        this.changeDate()
        this.visible = true
    }

    // 关闭弹窗
    close() {
        this.visible = false
    }
}
