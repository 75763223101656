import request from '@/plugins/axios'

/** S 财务概况 **/
// 财务概览
export const apiFinanceDataCenter = (params: any) => request.get('/finance.finance/dataCenter', { params })
/** E 财务概况 **/


/** S 门店报表 **/
// 门店报表列表
export const apiFinanceShopReportLists = (params: any) => request.get('/finance.shop_report/lists', { params })

// 门店报表列表
export const apiFinanceShopReportDataLists = (params: any) => request.get('/finance.shop_report/dateLists', { params })
/** E 门店报表 **/


/** S 合作商报表 **/
// 合作商报表列表
export const apiFinancePartnerReportLists = (params: any) => request.get('/finance.partner_report/lists', { params })

// 合作商报表列表
export const apiFinancePartnerReportDataLists = (params: any) => request.get('/finance.partner_report/dateLists', { params })
/** E 合作商报表 **/