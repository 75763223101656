
import {
    Component,
    Vue
} from 'vue-property-decorator'
import {
    apiFinanceShopReportLists
} from '@/api/finance'
import {
    RequestPaging
} from '@/utils/util'
import LsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'
import ShopLists from './components/shop-lists.vue'
import LsDialog from '../../components/ls-dialog.vue'
import DatePicker from '@/components/date-picker.vue'
@Component({
    components: {
        LsPagination,
        ExportData,
        LsDialog,
        ShopLists,
        DatePicker
    }
})
export default class UserManagement extends Vue {

    form = {
        start_time: '', //否	string	开始时间
        end_time: '' //否	string	结束时间
    }

    /** S Data **/
    // 分页查询
    pager: RequestPaging = new RequestPaging()
    /** E Data **/

    /** S Methods **/
    apiFinanceShopReportLists = apiFinanceShopReportLists // 传递给导出组件的api

    // 重置
    onReset() {
        this.form = {
            start_time: '', //否	string	开始时间
            end_time: '' //否	string	结束时间
        }
    
        this.getUserList()
    }

    // 查询按钮
    query() {
        this.pager.page = 1
        this.getUserList()
    }

    //获取用户列表数据
    getUserList() {
        this.pager.request({
            callback: apiFinanceShopReportLists,
            params: this.form
        })
    }
    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.getUserList()
    }
    /** E Life Cycle **/
}
